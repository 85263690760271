<template>
  <!-- @click.stop prevent the click event to be propagated to the parent, e.g. DropdownButton -->
  <button class="TagClose" @click.stop>
    <Icon :icon="mapIcon.close" />
  </button>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.TagClose {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  flex-shrink: 0;
  color: var(--gray-3);
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    color: var(--gray-2);
  }

  &:focus {
    border: 1px solid var(--blue-dark);
    outline: 4px solid var(--blue-light);
  }

  &:active {
    color: var(--gray-1);
  }
}
</style>
